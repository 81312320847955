import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'RRE Solutions', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to RRE', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Custom software for',
  name: 'your business.',
  subtitle: 'Here we build solutions.',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'Podcaster turned software developer',
  paragraphTwo: '',
  paragraphThree: '',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  //   {
  //     id: nanoid(),
  //     img: 'project.png',
  //     title: '',
  //     info: '',
  //     info2: '',
  //     url: '',
  //     repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  //   },
  //   {
  //     id: nanoid(),
  //     img: 'project.jpg',
  //     title: '',
  //     info: '',
  //     info2: '',
  //     url: '',
  //     repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  //   },
  //   {
  //     id: nanoid(),
  //     img: 'project.jpg',
  //     title: '',
  //     info: '',
  //     info2: '',
  //     url: '',
  //     repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  //   },
];

// CONTACT DATA
export const contactData = {
  cta: 'Would you like to work with us? Great!',
  btn: 'Reach out',
  email: 'ray@rre.solutions',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/rayelward',
    },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/raymond-elward-3630ab44/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/rayelward',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
